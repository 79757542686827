body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%
}

.monoton-regular {
  font-family: "Monoton", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 35px;
  color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, p {
  color: #ffffff;
  text-align: justify;
  text-justify: inter-word;
}

table {
  color: white;
  background-color: transparent;
}

th, td {
  color: inherit;
}

.container {
  min-height: 100vh;
  padding: 30px; 
  overflow: hidden;
  padding-top: 40px;
}

.login-page-container {
  position: relative;
  height: 100vh; /* Ensures the container takes the full height of the viewport */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Aligns items starting from the top */
  align-items: center;
  padding-top: 40%; /* Moves the login form slightly above center */
  overflow: hidden;
}

.login-container {
  width: 100%;
  max-width: 400px; /* Control the max width of the form */
  margin: 0 auto;
}

.home-page-container {
  position: relative;
  height: 100vh; /* Ensures the container takes the full height of the viewport */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Aligns items starting from the top */
  align-items: center;
  padding-top: 10%; /* Moves the login form slightly above center */
  overflow: hidden;
}

.home-container {
  width: 100%;
  max-width: 400px; /* Control the max width of the form */
  margin: 0 auto;
}

.btn-no-underline {
  text-decoration: none;
}

.a-no-underline {
  text-decoration: none;
}

.link-item {
  display: block; 
  margin-bottom: 10px; 
  text-decoration: none; 
  color: white; 
}

.modal p {
  color: black;
}

/*******************************************
Form group styling 
********************************************/
.form-group {
  margin-bottom: 15px; /* Adds space between form groups */
}

.login-container.custom-position {
  position: absolute;
  top: 40%; /* Adjust this to move the container up or down */
  transform: translateY(-50%); /* Adjusts the exact position */
  width: 100%; /* Takes full width of the col-sm-6 column */
}

/*******************************************
iFrame styling 
********************************************/
.iframe-container {
  width: 100%;   
  overflow: hidden; 
}

iframe {
  width: 100%;  
  height: 600px; 
  border: 0;    
}

/*******************************************
FAQ styling 
********************************************/

.faq-container {
  margin: 0
}

.faq-title {
  margin-bottom: 20px;
}

.faq-item {
  margin-bottom: 30px;
}

.faq-question {
  font-size: 1em;
  font-weight: bold;
  margin: 0;
}

.faq-answer {
  font-size: .7em;
  margin: 5px 0 0 0;
  line-height: 1.6;
  text-align: left; 
}

/*******************************************
Disco Ball Image
********************************************/

.disco-bottom-image-container {
  margin-top: 20px;
  position: absolute; 
  bottom: 0;
  left: 50%;
  width: 150vw;
  height: 25vh;
  overflow: hidden;
  transform: translateX(-50%); 
}

.disco-bottom-image {
  width: 100%;
  height: auto; 
}

.disco-top-image-container {
  position: absolute; 
  top: 0;
  left: 50%;
  width: 150vw;
  height: 20vh;
  overflow: hidden;
  transform: translateX(-50%); 
}
